import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/header/Header";
import Layout from "../common/layout";
import Footer from "../components/section/footer/v2";
import Mine from "../pages/mine";
import Stake from "../pages/stake";
import RewardPool from "../pages/rewardpool";
import BurnPool from "../pages/burnpool";
import Stats from "../pages/stats";
import Calculator from "../pages/calculator";
import Treasury from "../pages/treasury";
import Janus from "../pages/janus";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useReadContract, useReadContracts, useChainId } from "wagmi";
import BuynBurnContract from "../contract/buyandburn.json";
import { useDispatch } from "react-redux";
import { BuyandburnAddress, WETHTitanPoolAddress, WETHUsdtPoolAddress, JanusWPLSPoolAddress, JanusContractAddress } from "../const/const";
import pool from "../contract/pool.json";
import wplsPool from "../contract/wplsPool.json";
import BigNumber from "bignumber.js";
import { setEtherToUsd, setTitanxToUsd, setHlxToUsd, setJnsToUsd, setPlsToUsd } from "../store/price/index";
import { getEthertoUsd, getHlxtoUsd, getTitanxtoUsd } from "../store/price/actions";
import { pulsechain, mainnet } from "wagmi/chains";
import axios from "axios";
import WelcomePopup from "../components/WelcomePopup/WelcomePopup";

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const chainId = useChainId();
    const [titanHlxPool, setTitanHlxPool] = useState("");
    const [titanHlxPrice, setTitanHlxPrice] = useState("0");
    const [titanEthPrice, setTitanEthPrice] = useState("0");
    const [ethUsdtPrice, setEthUsdtPrice] = useState("0");
    const [plsUsdtPrice, setPlsUsdtPrice] = useState("0");
    const [jnsUsdtPrice, setJnsUsdtPrice] = useState("0");

    const buyAndBurnObj = {
        address: BuyandburnAddress,
        abi: BuynBurnContract.abi,
    };
    const jnsPoolObj = {
        address: JanusWPLSPoolAddress,
        abi: wplsPool.abi,
    };

    const { data: hlxPool } = useReadContract({
        ...buyAndBurnObj,
        functionName: "getPoolAddress",
    });

    useEffect(() => {
        setTitanHlxPool(hlxPool ? hlxPool.toString() : "");
    }, [hlxPool]);

    const { data: slot0 } = useReadContracts({
        contracts: [
            {
                address: titanHlxPool,
                abi: pool.abi,
                functionName: "slot0",
                chainId: mainnet.id,
            },
            {
                address: WETHUsdtPoolAddress,
                abi: pool.abi,
                functionName: "slot0",
                chainId: mainnet.id,
            },
            {
                address: WETHTitanPoolAddress,
                abi: pool.abi,
                functionName: "slot0",
                chainId: mainnet.id,
            },
        ],
    });

    const { data: jnsData } = useReadContracts({
        contracts: [
            {
                ...jnsPoolObj,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                ...jnsPoolObj,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    useEffect(() => {
        if (jnsData && jnsData[0].status === "success") {
            const token0 = jnsData[0].result;
            if (token0 === JanusContractAddress) {
                const jnsPriceInPLS = parseFloat(jnsData[1].result[1].toString()) / parseFloat(jnsData[1].result[0].toString());
                if (plsUsdtPrice !== 0) {
                    setJnsUsdtPrice((parseFloat(jnsPriceInPLS.toString()) * parseFloat(plsUsdtPrice)).toFixed(18));
                }
            } else {
                const jnsPriceInPLS = parseFloat(jnsData[1].result[0].toString()) / parseFloat(jnsData[1].result[1].toString());

                if (plsUsdtPrice !== 0) {
                    setJnsUsdtPrice((parseFloat(jnsPriceInPLS.toString()) * parseFloat(plsUsdtPrice)).toFixed(18));
                }
            }
        }
    }, [jnsData, plsUsdtPrice]);

    useEffect(() => {
        if (slot0 && slot0[0].status === "success") {
            setTitanHlxPrice(
                slot0[0]
                    ? new BigNumber(slot0[0].result[0].toString())
                          .times(new BigNumber(slot0[0].result[0].toString()))
                          .div(BigNumber(2).pow(192))
                          .toPrecision()
                    : 0
            );
            const token0Decimals = 18; // ETH
            const token1Decimals = 6; // USDT
            const decimalAdjustment = new BigNumber(10).pow(token0Decimals - token1Decimals);
            setEthUsdtPrice(
                slot0[1]
                    ? new BigNumber(slot0[1].result[0].toString())
                          .times(new BigNumber(slot0[1].result[0].toString()))
                          .div(BigNumber(2).pow(192))
                          .times(decimalAdjustment)
                          .toPrecision()
                    : 0
            );
            setTitanEthPrice(
                slot0[2]
                    ? BigNumber(2)
                          .pow(192)
                          .div(new BigNumber(slot0[2].result[0].toString()).times(new BigNumber(slot0[2].result[0].toString())))
                          .toPrecision()
                    : 0
            );
        }
    }, [slot0]);

    useEffect(() => {
        dispatch(setEtherToUsd(ethUsdtPrice));
        dispatch(setTitanxToUsd((titanEthPrice * ethUsdtPrice).toFixed(10)));
        dispatch(setHlxToUsd((titanHlxPrice * (titanEthPrice * ethUsdtPrice)).toFixed(10)));
    }, [ethUsdtPrice, titanEthPrice, titanHlxPrice, dispatch]);
    useEffect(() => {
        dispatch(setPlsToUsd(plsUsdtPrice));
        dispatch(setJnsToUsd(jnsUsdtPrice));
    }, [plsUsdtPrice, jnsUsdtPrice, dispatch]);

    useEffect(() => {
        axios
            .get("https://api.coingecko.com/api/v3/simple/price?ids=pulseChain&vs_currencies=usd")
            .then((response) => {
                if (response.data) {
                    setPlsUsdtPrice(response.data.pulsechain.usd);
                    localStorage.setItem("plsUsdPrice", response.data.pulsechain.usd);
                }
            })
            .catch((error) => {
                console.error("Failed to fetch PLS price:", error);
                setPlsUsdtPrice(localStorage.getItem("plsUsdPrice"));
                // You could set a default or handle the error more gracefully
            });
    }, []);

    useEffect(() => {
        if (chainId !== 1) {
            dispatch(getHlxtoUsd());
            dispatch(getEthertoUsd());
            dispatch(getTitanxtoUsd());
        }
    }, [dispatch, chainId]);

    const isJanus = location.pathname === "/janus";

    return (
        <Layout>
            <GlobalStyles isJanus={isJanus} />
            <Header />
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/mine" element={<Mine />} />
                <Route path="/stake" element={<Stake />} />
                <Route path="/rewardpools" element={<RewardPool />} />
                <Route path="/treasury" element={<Treasury />} />
                <Route path="/burnpools" element={<BurnPool />} />
                <Route path="/stats" element={<Stats />} />
                <Route path="/calculator" element={<Calculator />} />
                <Route path="/janus" element={<Janus />} />
            </Routes>
            <Footer />
            <WelcomePopup />
        </Layout>
    );
}

export default App;

import "./WelcomePopup.css";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { ReactComponent as XIcon } from "./icons/x-icon.svg";
import { ReactComponent as TelegramIcon } from "./icons/telegram.svg";
import { ReactComponent as YoutubeIcon } from "./icons/youtube.svg";
import TitanHub from "./icons/titanxhub.png";
import HlxLogo from "./images/helios.png";
import HlxImg from "./images/helios.jpg";
import JanusLogo from "./images/janus.png";
import JanusImg from "./images/janus.jpg";

const WelcomePopup = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [doNotShow, setDoNotShow] = useState(false);

    useEffect(() => {
        const popupDismissed = localStorage.getItem("welcome_popup_dismissed");
        if (!popupDismissed) {
            setIsVisible(true);
        }
    }, []);

    const handleDismiss = () => {
        if (doNotShow) {
            localStorage.setItem("welcome_popup_dismissed", "true");
        }
        setIsVisible(false);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setIsVisible(false);
        }
    };

    if (!isVisible) return null;

    return (
        <div className="welcome-popup" onClick={handleOverlayClick}>
            <div className="welcome-popup-content" onClick={(e) => e.stopPropagation()}>
                <Swiper
                    modules={[Autoplay, Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
                    navigation
                    loop={true}
                >
                    <SwiperSlide>
                        <div className="popup-card hlx">
                            <img className="popup-card-image" src={HlxImg} />
                            <div className="popup-card-content">
                                <img className="popup-logo" src={HlxLogo} alt="Helios" />
                                <h2>Welcome to Helios</h2>
                                <div className="separator" />
                                <p>
                                    <i>
                                        Helios (HLX) is an erc20 utility token on the Ethereum blockchain. It is a mining and staking protocol and the
                                        3rd official project to launch in the{" "}
                                        <a href="https://www.titanxhub.com/" target="_blank">
                                            TitanX/X28 ecosystem
                                        </a>
                                        , a system known for its supply reduction and buy pressure mechanisms.
                                    </i>
                                </p>
                                <h5>New to Helios? Here's what sets us apart:</h5>
                                <ul>
                                    <li>
                                        <strong>Helios leverages investment pools to feed our buy and burn and stakers.</strong> The investment pool
                                        is filled through the{" "}
                                        <a href="https://element280.win/" target="_blank">
                                            Element Ecosystem
                                        </a>
                                        , TitanX staking, Janus on Pulse, and more.
                                    </li>
                                    <li>
                                        <strong>Participants in the Helios ecosystem earn rewards in TITANX, ETH, and X28.</strong> With Staking V2,
                                        HLX stakers will have the opportunity to earn ANY ERC20 as part of their staking rewards.
                                    </li>
                                    <li>
                                        <strong>Helios is DAO controlled.</strong> Community members have a say in how funds are used through
                                        governance.
                                    </li>
                                </ul>
                                <div className="welcome-popup-buttons">
                                    <a href="https://docs.helios-hlx.win/helios/" target="_blank">
                                        <button className="btn2">Learn More</button>
                                    </a>
                                    <button onClick={handleDismiss}>ENTER APP</button>
                                </div>
                                <div className="welcome-popup-footer">
                                    <div className="socials">
                                        <a href="https://www.titanxhub.com/" target="_blank">
                                            <img src={TitanHub} />
                                        </a>
                                        <a href="https://x.com/Helios_HLX" target="_blank">
                                            <XIcon />
                                        </a>
                                        <a href="https://t.me/Helios_HLX/33670" target="_blank">
                                            <TelegramIcon />
                                        </a>
                                        <a href="https://www.youtube.com/@Helios-hlx" target="_blank">
                                            <YoutubeIcon />
                                        </a>
                                    </div>
                                    <div className="welcome-popup-checkbox">
                                        <input type="checkbox" id="dont-show-again" checked={doNotShow} onChange={() => setDoNotShow(!doNotShow)} />
                                        <label htmlFor="dont-show-again">Do not show this again</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="popup-card janus">
                            <img className="popup-card-image" src={JanusImg} />
                            <div className="popup-card-content">
                                <img className="popup-logo" src={JanusLogo} alt="Janus" />
                                <h2>Welcome to Janus</h2>
                                <div className="separator" />
                                <p>
                                    <i>
                                        Janus is an innovative extension of the Helios ecosystem, designed to enhance value and expand our reach
                                        across multiple blockchains. By leveraging arbitrage opportunities and implementing strategic tokenomics,
                                        Janus offers several benefits to Helios:
                                    </i>
                                </p>
                                <ul>
                                    <li>
                                        <strong>Arbitrage Opportunities:</strong> Janus pairs with multiple tokens across various liquidity pools,
                                        creating arbitrage possibilities. These activities generate transaction fees that are reinvested into the
                                        ecosystem, enhancing the value of both JNS and HLX tokens.
                                    </li>
                                    <li>
                                        <strong>HLX Supply Reduction:</strong> To mint JNS, users must burn HLX tokens and pay a protocol fee in
                                        Pulse. This process reduces the circulating supply of HLX, potentially increasing its value over time.
                                    </li>
                                    <li>
                                        <strong>A portion of Janus fees goes to the Helios DAO,</strong> empowering the community to decide on fund
                                        use, like burns, staking rewards, or treasury growth, fostering a decentralized ecosystem.
                                    </li>
                                </ul>
                                <div className="welcome-popup-buttons">
                                    <a href="https://docs.helios-hlx.win/janus" target="_blank">
                                        <button className="btn2">Learn More</button>
                                    </a>
                                    <button onClick={handleDismiss}>ENTER APP</button>
                                </div>
                                <div className="welcome-popup-footer">
                                    <div className="socials">
                                        <a href="https://www.titanxhub.com/" target="_blank">
                                            <img src={TitanHub} />
                                        </a>
                                        <a href="https://x.com/Helios_HLX" target="_blank">
                                            <XIcon />
                                        </a>
                                        <a href="https://t.me/Helios_HLX/33670" target="_blank">
                                            <TelegramIcon />
                                        </a>
                                        <a href="https://www.youtube.com/@Helios-hlx" target="_blank">
                                            <YoutubeIcon />
                                        </a>
                                    </div>
                                    <div className="welcome-popup-checkbox">
                                        <input type="checkbox" id="dont-show-again" checked={doNotShow} onChange={() => setDoNotShow(!doNotShow)} />
                                        <label htmlFor="dont-show-again">Do not show this again</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default WelcomePopup;

import React, { useEffect, useState } from "react";
import TreasuryContentStyleWrapper from "./TreasuryContent.style";
import DashboardText from "../../common/dashboardtext/DashboardText";
import tooltipText from "../../assets/helius/tooltipText.json";
import heliosContract from "../../contract/helios.json";
import treasuryContract from "../../contract/treasury.json";
import titanxContract from "../../contract/titanx.json";
import wplsPool from "../../contract/wplsPool.json";
import wonderlandAbi from "../../contract/wonderLand.json";
import investAbi from "../../contract/JNSInvest.json";

import {
    useAccount,
    useReadContract,
    useReadContracts,
    useWriteContract,
    useWaitForTransactionReceipt,
    useBalance,
    useSwitchChain,
    useChainId,
} from "wagmi";
import { useSelector } from "react-redux";
import {
    TreasuryAddress,
    HeliosAddress,
    etherToFixed,
    TitanxAddress,
    InvestmentPoolAddressPls,
    BunsAddress,
    AliceAddress,
    HatterAddress,
    CheshireAddress,
    QueenAdress,
    BunsTreasuryAddress,
    BunsPlsPoolAddress,
    AlicePlsPoolAddress,
    HatterPlsPoolAddress,
    CheshirePlsPoolAddress,
    QueenPlsPoolAddress,
    Hyper,
    Dragonx,
    BabyDragonX,
    Blaze,
    Inferno,
    JakeX,
    HYDRA_ADDRESS,
    AWESOMEX_ADDRESS,
    FLUX_ADDRESS,
    VOLT_ADDRESS,
    LGNDX_ADDRESS,
    TINC_ADDRESS,
    E280_ADDRESS,
    WPLS_ADDRESS,
    InvestmentAddress,
    INVEST_POOL_SLIPPAGE,
    INVEST_POOL_DEADLINE,
} from "../../const/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaxStakeTooltip from "../modal/MaxStakeTooltip";
import ClaimDistTooltip from "../modal/ClaimDistTooltip";
import { ethers } from "ethers";
import { pulsechain, mainnet } from "wagmi/chains";
import { getCoinPrice, getCoinPricesGecko, getPoolInfosGecko, getTooltipText } from "../../lib/getCoinPrice";
import WonderTreasuryTooltip from "../modal/WonderTreasuryTooltip";
import JNSInvestPoolTooltip from "../modal/JNSInvestPoolTooltip";
import { getBunsLimitPrice } from "../../lib/quoting";

const TreasuryContent = () => {
    const { address, isConnected } = useAccount();

    // Start Get Redux store variables
    const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
    const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd); // Fetch the value of TitanX price from redux
    const pls_to_usd = useSelector((state) => state.price.pls_to_usd);
    const hlx_to_usd = useSelector((state) => state.price.hlx_to_usd);
    const { switchChain } = useSwitchChain();
    const chainId = useChainId();
    const [liquidTitanX, setLiquidTitanX] = useState(0);
    const [titanxStaked, setTitanxStaked] = useState(0);
    const [claimableETH, setClaimableETH] = useState(0);
    const [investmentBalance, setInvestimentBalance] = useState(0);
    const [bunsTreasuryWPLSBalance, setBunsTreasuryWPLSBalance] = useState(0n);
    const [investmentPoolWPLSBalance, setInvestmentPoolWPLSBalance] = useState(0n);
    const [investmentPlsBalance, setInvestimentPlsBalance] = useState(0);
    const [bunsTreasuryPlsBalance, setBunsTreasuryPlsBalance] = useState(0);
    const [bunsTreasuryBunsBalance, setBunsTreasuryBunsBalance] = useState(0);
    const [bunsTreasuryAliceBalance, setBunsTreasuryAliceBalance] = useState(0);
    const [bunsTreasuryHatterBalance, setBunsTreasuryHatterBalance] = useState(0);
    const [bunsTreasuryCheshireBalance, setBunsTreasuryCheshireBalance] = useState(0);
    const [bunsTreasuryQueenBalance, setBunsTreasuryQueenBalance] = useState(0);

    const [activeHlxStakeContract, setActiveHlxStakeContract] = useState(TreasuryAddress);
    const [disableButton, setDisableButton] = useState(false);

    const [bunsUsdtPrice, setBunsUsdtPrice] = useState(0);
    const [aliceUsdtPrice, setAliceUsdtPrice] = useState(0);
    const [hatterUsdtPrice, setHatterUsdtPrice] = useState(0);
    const [cheshireUsdtPrice, setCheshireUsdtPrice] = useState(0);
    const [queenUsdtPrice, setQueenUsdtPrice] = useState(0);
    const [userEther08, setuserEther08] = useState(0);
    const [userEther28, setuserEther28] = useState(0);
    const [userEther90, setuserEther90] = useState(0);
    const [userEther369, setuserEther369] = useState(0);
    const [userEther888, setuserEther888] = useState(0);

    const [lpUsdValues, setLpUsdValues] = useState({});
    const [tokenData, setTokenData] = useState({});
    const [tokenPrices, setTokenPrices] = useState({});

    const heliosObj = {
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet.id,
    };
    const treasuryObj = {
        address: TreasuryAddress,
        abi: treasuryContract.abi,
        chainId: mainnet.id,
    };
    const titanxObj = {
        address: TitanxAddress,
        abi: titanxContract.abi,
        chainId: mainnet.id,
    };

    const { data: bunsPoolData } = useReadContracts({
        contracts: [
            {
                address: BunsPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: BunsPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: alicePoolData } = useReadContracts({
        contracts: [
            {
                address: AlicePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: AlicePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: hatterPoolData } = useReadContracts({
        contracts: [
            {
                address: HatterPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: HatterPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: cheshirePoolData } = useReadContracts({
        contracts: [
            {
                address: CheshirePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: CheshirePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: queensPoolData } = useReadContracts({
        contracts: [
            {
                address: QueenPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: QueenPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    useEffect(() => {
        if (bunsPoolData && bunsPoolData[0].status === "success") {
            const token0 = bunsPoolData[0].result;
            if (token0 === BunsAddress) {
                const BunsPriceInPLS = parseFloat(bunsPoolData[1].result[1].toString()) / parseFloat(bunsPoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setBunsUsdtPrice((parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const BunsPriceInPLS = parseFloat(bunsPoolData[1].result[0].toString()) / parseFloat(bunsPoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setBunsUsdtPrice((parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [bunsPoolData, pls_to_usd]);

    useEffect(() => {
        if (alicePoolData && alicePoolData[0].status === "success") {
            const token0 = alicePoolData[0].result;
            if (token0 === AliceAddress) {
                const AlicePriceInPLS = parseFloat(alicePoolData[1].result[1].toString()) / parseFloat(alicePoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setAliceUsdtPrice((parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const AlicePriceInPLS = parseFloat(alicePoolData[1].result[0].toString()) / parseFloat(alicePoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setAliceUsdtPrice((parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [alicePoolData, pls_to_usd]);

    useEffect(() => {
        if (hatterPoolData && hatterPoolData[0].status === "success") {
            const token0 = hatterPoolData[0].result;
            if (token0 === HatterAddress) {
                const HatterPriceInPLS = parseFloat(hatterPoolData[1].result[1].toString()) / parseFloat(hatterPoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setHatterUsdtPrice((parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const HatterPriceInPLS = parseFloat(hatterPoolData[1].result[0].toString()) / parseFloat(hatterPoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setHatterUsdtPrice((parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [hatterPoolData, pls_to_usd]);

    useEffect(() => {
        if (cheshirePoolData && cheshirePoolData[0].status === "success") {
            const token0 = cheshirePoolData[0].result;
            if (token0 === CheshireAddress) {
                const CheshirePriceInPLS =
                    parseFloat(cheshirePoolData[1].result[1].toString()) / parseFloat(cheshirePoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setCheshireUsdtPrice((parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const CheshirePriceInPLS =
                    parseFloat(cheshirePoolData[1].result[0].toString()) / parseFloat(cheshirePoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setCheshireUsdtPrice((parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [cheshirePoolData, pls_to_usd]);

    useEffect(() => {
        if (queensPoolData && queensPoolData[0].status === "success") {
            const token0 = queensPoolData[0].result;
            if (token0 === QueenAdress) {
                const QueensPriceInPLS = parseFloat(queensPoolData[1].result[1].toString()) / parseFloat(queensPoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setQueenUsdtPrice((parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const QueensPriceInPLS = parseFloat(queensPoolData[1].result[0].toString()) / parseFloat(queensPoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setQueenUsdtPrice((parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [queensPoolData, pls_to_usd]);

    //////////////// CHANGE /////////////////
    // PulseChain Treasury Contracts
    const plsTreasuryContracts = [
        { address: BunsAddress, abi: heliosContract.abi },
        { address: AliceAddress, abi: heliosContract.abi },
        { address: HatterAddress, abi: heliosContract.abi },
        { address: CheshireAddress, abi: heliosContract.abi },
        { address: QueenAdress, abi: heliosContract.abi },
        { address: WPLS_ADDRESS, abi: heliosContract.abi },
    ].map(({ address, abi }) => ({
        address,
        abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
    }));

    // Additional PulseChain Investment Pool Contract
    plsTreasuryContracts.push({
        address: WPLS_ADDRESS,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [InvestmentPoolAddressPls],
        chainId: pulsechain.id,
    });

    // State setters mapping for PulseChain and Mainnet
    const plsStateSetters = [
        setBunsTreasuryBunsBalance,
        setBunsTreasuryAliceBalance,
        setBunsTreasuryHatterBalance,
        setBunsTreasuryCheshireBalance,
        setBunsTreasuryQueenBalance,
        setBunsTreasuryWPLSBalance,
        setInvestmentPoolWPLSBalance,
    ];

    // Fetch data using `useReadContracts`
    const { data: plsTreasuryReads } = useReadContracts({
        contracts: plsTreasuryContracts,
        watch: true,
    });

    // Effect to update state values
    useEffect(() => {
        if (plsTreasuryReads?.length) {
            plsTreasuryReads.forEach((read, index) => {
                plsStateSetters[index](read?.status === "success" ? read.result : 0n);
            });
        }
    }, [plsTreasuryReads]);

    const treasuryTokens = [
        { address: TitanxAddress, elmntLp: "0x8668601064CF7a89f634cb31a97B2e0944BDD956", name: "TitanX", ticker: "TITANX" },
        { address: HeliosAddress, elmntLp: "0xCc4d3Fc2C9fb0180f955232d5363C47Fc386F30E", name: "Helios", ticker: "HLX" },
        { address: Hyper, elmntLp: "0x6EC60e2F710DB0F4117aAB839A2A96e24103cFF5", name: "Hyper", ticker: "HYPER" },
        { address: Dragonx, elmntLp: "0x2139BAd88A696254529Ac22bF20c12Ab8C08c3dB", name: "DragonX", ticker: "DRAGONX" },
        { address: BabyDragonX, elmntLp: "0xc452b86659FEb7fAF4B705157a93745253341CD1", name: "Baby DragonX", ticker: "BDX" },
        { address: Blaze, elmntLp: "0xe876D2e85B72D6F27bB7Ac23918a4f4c565Dd08b", name: "Blaze", ticker: "BLAZE" },
        { address: Inferno, elmntLp: "0x4C50860049126B28B2e8A72F72dca4b609bb3b49", name: "Inferno", ticker: "INF" },
        { address: JakeX, elmntLp: "0xf82169C950b5934f0550C247D93BF37DcEd19211", name: "JakeX", ticker: "JAKEX" },
        { address: HYDRA_ADDRESS, elmntLp: "0xB96a0bB6070210c0c8121291Ee99177C4ab0A555", name: "Hydra", ticker: "HYDRA" },
        { address: AWESOMEX_ADDRESS, elmntLp: "0x6711881b9f12C99591dB8AF228A59D00f1DAA313", name: "AwesomeX", ticker: "AWX" },
        { address: FLUX_ADDRESS, elmntLp: "0xdDc676536ac82B3Ea0bf2275813ca2BBD7F4EcAB", name: "Flux", ticker: "FLUX" },
        { address: VOLT_ADDRESS, elmntLp: "0xE9424259a3587014479451e55BD990174D095b60", name: "Volt", ticker: "VOLT" },
        { address: LGNDX_ADDRESS, elmntLp: "0x0D46E0f94A343E5a65F0dc7f5934740e51A54e01", name: "LegendX", ticker: "LGNDX" },
        { address: TINC_ADDRESS, elmntLp: "0xcA769EeFA37905A279B0932bED8657861CeBDB26", name: "Titan Farms", ticker: "TINC" },
        { address: E280_ADDRESS, elmntLp: "0x8668601064CF7a89f634cb31a97B2E0944BDD956", name: "Element 280", ticker: "ELMNT" },
    ];

    const treasuryContracts = treasuryTokens.map(({ address }) => ({
        address,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [InvestmentAddress],
        chainId: mainnet.id,
    }));

    const lpContractsBalances = treasuryTokens.map((token) => ({
        address: token.elmntLp.toLowerCase(),
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [InvestmentAddress],
        chainId: mainnet.id,
    }));

    const lpContractsTotalSupplies = treasuryTokens.map((token) => ({
        address: token.elmntLp.toLowerCase(),
        abi: titanxContract.abi,
        functionName: "totalSupply",
        chainId: mainnet.id,
    }));

    const { data: tokenBalancesData } = useReadContracts({
        contracts: treasuryContracts,
        watch: true,
    });

    const { data: lpBalancesData } = useReadContracts({
        contracts: lpContractsBalances,
        watch: true,
    });

    const { data: lpTotalSuppliesData } = useReadContracts({
        contracts: lpContractsTotalSupplies,
        watch: true,
    });

    useEffect(() => {
        async function fetchTokenPrices() {
            try {
                const tokenAddresses = treasuryTokens.map((token) => token.address);

                const prices = await getCoinPricesGecko(tokenAddresses);

                setTokenPrices(
                    treasuryTokens.reduce((acc, token) => {
                        acc[token.address.toLowerCase()] = prices[token.address.toLowerCase()]?.price || 0;
                        return acc;
                    }, {})
                );
            } catch (error) {
                console.error("Error fetching token prices:", error);
            }
        }

        fetchTokenPrices();
    }, []);

    useEffect(() => {
        async function fetchPoolData() {
            try {
                const tokensWithoutElmnt = treasuryTokens.slice(0, -1);
                const poolValues = await getPoolInfosGecko(tokensWithoutElmnt.map((token) => token.elmntLp));

                setLpUsdValues(
                    tokensWithoutElmnt.reduce((acc, token, index) => {
                        acc[token.address.toLowerCase()] = poolValues[index] || 0;
                        return acc;
                    }, {})
                );
            } catch (error) {
                console.error("Error fetching pool data:", error);
            }
        }

        fetchPoolData();
    }, []);

    useEffect(() => {
        if (tokenBalancesData && lpBalancesData && lpTotalSuppliesData && tokenPrices) {
            const newTokenData = {};

            treasuryTokens.forEach((token, index) => {
                const tokenBalance = tokenBalancesData[index]?.status === "success" ? tokenBalancesData[index].result : 0n;
                const lpBalance = lpBalancesData[index]?.status === "success" ? lpBalancesData[index].result : 0n;
                const lpTotalSupply = lpTotalSuppliesData[index]?.status === "success" ? lpTotalSuppliesData[index].result : 0n;

                const poolUsdValue = lpUsdValues[token.address.toLowerCase()] || 0;
                const tokenPrice = tokenPrices[token.address.toLowerCase()] || 0;

                const ownershipPercentage = lpTotalSupply > 0 ? (Number(lpBalance) / Number(lpTotalSupply)) * 100 : 0;
                const ownedValueInUSD = (ownershipPercentage / 100) * poolUsdValue;
                const tokenUsdValue = parseFloat(etherToFixed(tokenBalance)) * tokenPrice;

                newTokenData[token.address.toLowerCase()] = {
                    tokenBalance,
                    lpBalance,
                    ownershipPercentage,
                    ownedValueInUSD,
                    tokenUsdValue,
                };
            });

            setTokenData(newTokenData);
        }
    }, [tokenBalancesData, lpBalancesData, lpTotalSuppliesData, lpUsdValues, tokenPrices]);

    const { data } = useReadContracts({
        contracts: [
            {
                ...heliosObj,
                functionName: "getGlobalActiveShares",
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [22],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [69],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [420],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [22],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [69],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [420],
            },
            {
                ...heliosObj,
                functionName: "calculateUserCycleReward",
                args: [address, 22],
            },
            {
                ...heliosObj,
                functionName: "calculateUserCycleReward",
                args: [address, 69],
            },
            {
                ...heliosObj,
                functionName: "calculateUserCycleReward",
                args: [address, 420],
            },
            {
                ...heliosObj,
                functionName: "getUndistributedTitanX",
            },
            {
                ...heliosObj,
                functionName: "getUndistributedETH",
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [8],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [28],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [90],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [369],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [888],
            },
        ],
        watch: true,
    });

    const { data: treasuryData } = useReadContracts({
        contracts: [
            {
                ...treasuryObj,
                functionName: "getTitanBalance",
            },
            {
                ...treasuryObj,
                functionName: "getTotalTitanStaked",
            },
            {
                ...treasuryObj,
                functionName: "getTotalTitanUnstaked",
            },
            {
                ...titanxObj,
                functionName: "getUserETHClaimableTotal",
                args: [activeHlxStakeContract],
            },
            {
                ...treasuryObj,
                functionName: "activeHlxStakeContract",
            },
        ],
        watch: true,
    });

    const { data: userEst } = useReadContracts({
        contracts: [
            {
                ...titanxObj,
                functionName: "getGlobalActiveShares",
            },
            {
                ...titanxObj,
                functionName: "getUserCurrentActiveShares",
                args: [activeHlxStakeContract],
            },
        ],
        watch: true,
    });

    /**
     *@description: Max Stake button stakeTITANX()
     */
    const { data: maxStakeData, writeContract: maxStake, error: maxStakeError, isLoading: isMaxStakeLoading } = useWriteContract();

    const maxStakeClicked = async () => {
        if (chainId !== mainnet.id) {
            toast.error("Please switch your network to ETH mainnet", {
                autoClose: 5000,
            });
            return;
        }
        maxStake({
            address: TreasuryAddress,
            abi: treasuryContract.abi,
            functionName: "stakeTITANX",
            args: [],
            chainId: mainnet.id,
        });
    };

    const { isSuccess: isMaxStakeConfirmed } = useWaitForTransactionReceipt({
        hash: maxStakeData,
    });

    useEffect(() => {
        if (maxStakeError) {
            toast.error("Minimum wait time of 7 days or minimum 100B TITANX balance required", {
                autoClose: 5000,
            });
        }
    }, [maxStakeError]);

    useEffect(() => {
        if (isMaxStakeConfirmed) {
            toast.success("Staked Successfully", {
                autoClose: 5000,
            });
        }
    }, [isMaxStakeConfirmed]);

    /**
     *@description: Claim Distribute -> claimReward()
     */
    const {
        data: claimDistributeData,
        writeContract: claimDistribute,
        error: claimDistributeError,
        isLoading: isClaimDistributeLoading,
    } = useWriteContract();

    const { isSuccess: isClaimDistributeConfirmed } = useWaitForTransactionReceipt({
        hash: claimDistributeData,
    });

    useEffect(() => {
        if (claimDistributeError) {
            toast.error("There is no ETH to claim", {
                autoClose: 5000,
            });
        }
    }, [claimDistributeError]);

    /**
     *@description: Wonderland Treasury -> distributePLS()
     */
    const {
        data: treasuryDistributeData,
        writeContract: treasuryDistribute,
        error: treasuryDistributeError,
        isLoading: isTreasuryDistributeLoading,
    } = useWriteContract();

    const { isSuccess: isTreasuryDistributeConfirmed } = useWaitForTransactionReceipt({
        hash: treasuryDistributeData,
    });

    useEffect(() => {
        if (treasuryDistributeError) {
            toast.error("No PLS Available for distribution", {
                autoClose: 5000,
            });
        }
    }, [treasuryDistributeError]);

    useEffect(() => {
        if (isTreasuryDistributeConfirmed) {
            toast.success("Treasury Distributed Successfully", {
                autoClose: 5000,
            });
        }
    }, [isTreasuryDistributeConfirmed]);

    const treasutyDistributeClicked = async () => {
        if (chainId !== pulsechain.id) {
            toast.error("Please switch your network to Pulse chain", {
                autoClose: 5000,
            });
            return;
        }
        treasuryDistribute({
            address: BunsTreasuryAddress,
            abi: wonderlandAbi.abi,
            functionName: "distributePLS",
            args: [],
        });
    };

    const { data: JNSInvestData } = useReadContracts({
        contracts: [
            {
                address: InvestmentPoolAddressPls,
                abi: investAbi.abi,
                chainId: pulsechain.id,
                functionName: "buyBurnShareBPS",
            },
            {
                address: InvestmentPoolAddressPls,
                abi: investAbi.abi,
                chainId: pulsechain.id,
                functionName: "incentiveFee",
            },
            {
                address: InvestmentPoolAddressPls,
                abi: investAbi.abi,
                chainId: pulsechain.id,
                functionName: "capPerDistribution",
            },
        ],
        watch: true,
    });

    /**
     *@description: JNSInvestmentPool -> distributePLS()
     */
    const {
        data: jnsTreasuryDistributeData,
        writeContract: jnsTreasuryDistribute,
        error: jnsTreasuryDistributeError,
        isLoading: isJnsTreasuryDistributeLoading,
    } = useWriteContract();

    const { isSuccess: isJnsTreasuryDistributeConfirmed } = useWaitForTransactionReceipt({
        hash: jnsTreasuryDistributeData,
    });

    useEffect(() => {
        if (jnsTreasuryDistributeError) {
            if (jnsTreasuryDistributeError.metaMessages[0] === "Error: Unauthorized()") {
                toast.error("You are not whitelisted", {
                    autoClose: 5000,
                });
                return;
            }
            if (jnsTreasuryDistributeError.metaMessages[0] === "Error: Cooldown()") {
                toast.error("Distribution cooldown in progress", {
                    autoClose: 5000,
                });
                return;
            }
            toast.error(jnsTreasuryDistributeError.metaMessages[0], {
                autoClose: 5000,
            });
        }
    }, [jnsTreasuryDistributeError]);

    useEffect(() => {
        if (isJnsTreasuryDistributeConfirmed) {
            toast.success("Treasury Distributed Successfully", {
                autoClose: 5000,
            });
        }
    }, [isJnsTreasuryDistributeConfirmed]);

    const jnsTreasutyDistributeClicked = async () => {
        if (chainId !== pulsechain.id) {
            toast.error("Please switch your network to Pulse chain", {
                autoClose: 5000,
            });
            return;
        }
        if (investmentPlsBalance === 0n) {
            toast.error("No PLS Available for distribution", {
                autoClose: 5000,
            });
            return;
        }
        if (!JNSInvestData || !JNSInvestData[0].status === "success") {
            toast.error("Error loading JNS data", {
                autoClose: 5000,
            });
            return;
        }
        const buyBurnShareBPS = JNSInvestData[0].result;
        const incentiveFee = JNSInvestData[1].result;
        const distributionCap = JNSInvestData[2].result;
        const distrbutionValue = investmentPlsBalance > distributionCap ? distributionCap : investmentPlsBalance;
        const userIncentive = (etherToFixed(distrbutionValue) * incentiveFee) / 10000;
        const distributionAfterIncentive = etherToFixed(distrbutionValue) - userIncentive;
        const buyBurnValue = (distributionAfterIncentive * buyBurnShareBPS) / 10000;
        const bunsValue = distributionAfterIncentive - buyBurnValue;
        const amountIn = await getBunsLimitPrice(bunsValue);
        const minAmountOut = Math.round((parseInt(amountIn.toString()) * (10000 - INVEST_POOL_SLIPPAGE)) / 10000);
        const deadline = Math.round(Date.now() / 1000 + INVEST_POOL_DEADLINE);
        jnsTreasuryDistribute({
            address: InvestmentPoolAddressPls,
            abi: investAbi.abi,
            functionName: "distributePLS",
            args: [minAmountOut, deadline],
        });
    };

    const switchToChain = async (chainId) => {
        switchChain({ chainId });
    };

    useEffect(() => {
        if (data && data[12].status === "success") {
            if (userEst && userEst[0].status === "success") {
                if (parseFloat(userEst[0]?.result || 0) !== 0) {
                    const userEstRate =
                        (userEst[1] ? userEst[1]?.result?.toString() || 0 : 0) / (userEst[0] ? userEst[0]?.result?.toString() || 0 : 0);

                    setuserEther08(etherToFixed(data[12] ? data[12]?.result || 0 : 0) * userEstRate);
                    setuserEther28(etherToFixed(data[13] ? data[13]?.result || 0 : 0) * userEstRate);
                    setuserEther90(etherToFixed(data[14] ? data[14]?.result || 0 : 0) * userEstRate);
                    setuserEther369(etherToFixed(data[15] ? data[15]?.result || 0 : 0) * userEstRate);
                    setuserEther888(etherToFixed(data[16] ? data[16]?.result || 0 : 0) * userEstRate);
                }
            }
        }
    }, [data, userEst]);

    useEffect(() => {
        if (isClaimDistributeConfirmed) {
            toast.success("Claim Distributed Successfully", {
                autoClose: 5000,
            });
        }
    }, [isClaimDistributeConfirmed]);

    const claimDistributeClicked = async () => {
        if (chainId !== mainnet.id) {
            toast.error("Please switch your network to ETH mainnet", {
                autoClose: 5000,
            });
            return;
        }
        claimDistribute({
            address: TreasuryAddress,
            abi: treasuryContract.abi,
            functionName: "claimReward",
            args: [],
        });
    };

    const { data: userBalance } = useBalance({
        address: InvestmentAddress ? InvestmentAddress : ethers.constants.AddressZero,
        chainId: mainnet.id,
    });

    const { data: userPlsBalance } = useBalance({
        address: InvestmentPoolAddressPls,
        chainId: pulsechain.id,
    });

    const { data: userBunsTreasuryPLSBalance } = useBalance({
        address: BunsTreasuryAddress,
        chainId: pulsechain.id,
    });

    useEffect(() => {
        if (data && data[1].status === "success") {
            if ((data[11] ? data[11]?.result?.toString() || 0 : 0) === 0 && (data[10] ? data[10]?.result?.toString() || 0 : 0) === 0) {
                setDisableButton(true);
            } else {
                setDisableButton(false);
            }
        }
        if (userBalance) {
            setInvestimentBalance(userBalance ? userBalance.value : 0);
        }
        if (userPlsBalance || investmentPoolWPLSBalance) {
            let wpls = investmentPoolWPLSBalance;
            const native = userPlsBalance ? userPlsBalance.value : 0n;
            setInvestimentPlsBalance(native + wpls);
        }
        if (userBunsTreasuryPLSBalance || bunsTreasuryWPLSBalance) {
            let wpls = bunsTreasuryWPLSBalance;
            const native = userBunsTreasuryPLSBalance ? userBunsTreasuryPLSBalance.value : 0n;
            setBunsTreasuryPlsBalance(native + wpls);
        }

        if (treasuryData && treasuryData[0].status === "success") {
            setLiquidTitanX(treasuryData[0] ? treasuryData[0]?.result?.toString() || 0 : 0);
            setTitanxStaked(
                treasuryData[1]
                    ? treasuryData[2]
                        ? (
                              parseFloat(etherToFixed(treasuryData[1]?.result || 0)) - parseFloat(etherToFixed(treasuryData[2]?.result || 0))
                          )?.toString()
                        : 0
                    : 0
            );
            setActiveHlxStakeContract(treasuryData[4] ? treasuryData[4]?.result?.toString() || 0 : 0);
        }
    }, [
        data,
        treasuryData,
        disableButton,
        userBalance,
        userBunsTreasuryPLSBalance,
        userPlsBalance,
        investmentPoolWPLSBalance,
        bunsTreasuryWPLSBalance,
    ]);

    useEffect(() => {
        if (treasuryData && treasuryData[3].status === "success") {
            setClaimableETH(treasuryData[3] ? treasuryData[3]?.result?.toString() || 0 : 0);
        }
    }, [treasuryData]);

    return (
        <TreasuryContentStyleWrapper>
            <div className="rows">
                <div className="cols">
                    <div className="reward_pool_cards">
                        <div className="cards_contents">
                            <h1>Titanx Treasury </h1>

                            <div className="card_content_rewards">
                                <DashboardText
                                    token="TITANX"
                                    name="Liquid Titanx"
                                    balance={parseFloat(etherToFixed(liquidTitanX)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={parseFloat(titanx_to_usd * parseFloat(etherToFixed(liquidTitanX))).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "liquidtitanx" in asset)?.liquidtitanx}
                                />
                                <DashboardText
                                    token="TITANX"
                                    name="Titanx Staked"
                                    balance={parseFloat(titanxStaked).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={parseFloat(titanx_to_usd * parseFloat(titanxStaked)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "titanxstaked" in asset)?.titanxstaked}
                                />
                                <DashboardText
                                    token="ETH"
                                    name="Future Est. Payouts"
                                    balance={parseFloat(userEther08 + userEther28 + userEther90 + userEther369 + userEther888).toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4,
                                        }
                                    )}
                                    usd={parseFloat(
                                        ether_to_usd * parseFloat(userEther08 + userEther28 + userEther90 + userEther369 + userEther888)
                                    ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "estimateETHPayout" in asset)?.estimateETHPayout}
                                />
                                <DashboardText
                                    token="ETH"
                                    name="Claimable Rewards"
                                    balance={parseFloat(etherToFixed(claimableETH)).toLocaleString("en-US", {
                                        minimumFractionDigits: 4,
                                        maximumFractionDigits: 4,
                                    })}
                                    usd={parseFloat(ether_to_usd * parseFloat(etherToFixed(claimableETH))).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "claimablerewards" in asset)?.claimablerewards}
                                />
                            </div>
                            <div className="reward_pool_btn_group">
                                <MaxStakeTooltip>
                                    {chainId === mainnet.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isMaxStakeLoading)}
                                            onClick={maxStakeClicked}
                                        >
                                            MAX STAKE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isMaxStakeLoading)}
                                            onClick={() => switchToChain(mainnet.id)}
                                        >
                                            SWITCH TO ETH
                                        </button>
                                    )}
                                </MaxStakeTooltip>
                                <ClaimDistTooltip claimData={claimableETH}>
                                    {chainId === mainnet.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isClaimDistributeLoading)}
                                            onClick={claimDistributeClicked}
                                        >
                                            CLAIM & DISTRIBUTE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isClaimDistributeLoading)}
                                            onClick={() => switchToChain(mainnet.id)}
                                        >
                                            SWITCH TO ETH
                                        </button>
                                    )}
                                </ClaimDistTooltip>
                            </div>
                        </div>
                        <div className="cards_contents">
                            <h1>Wonderland Treasury </h1>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="QUEEN"
                                    name="Red"
                                    balance={parseFloat(etherToFixed(bunsTreasuryQueenBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryQueenBalance)) * queenUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "queenBalance" in asset)?.queenBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="CHESHIRE"
                                    name="Cat"
                                    balance={parseFloat(etherToFixed(bunsTreasuryCheshireBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryCheshireBalance)) * cheshireUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "cheshireBalance" in asset)?.cheshireBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="HATTER"
                                    name="Mad"
                                    balance={parseFloat(etherToFixed(bunsTreasuryHatterBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryHatterBalance)) * hatterUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "hatterBalance" in asset)?.hatterBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="ALICE"
                                    name="Wonderland"
                                    balance={parseFloat(etherToFixed(bunsTreasuryAliceBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryAliceBalance)) * aliceUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "aliceBalance" in asset)?.aliceBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="BUNS"
                                    name="White Rabbit"
                                    balance={parseFloat(etherToFixed(bunsTreasuryBunsBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryBunsBalance)) * bunsUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "bunsBalance" in asset)?.bunsBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="PLS"
                                    name="Pulse"
                                    balance={parseFloat(etherToFixed(bunsTreasuryPlsBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryPlsBalance)) * pls_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "bunsPlsBalance" in asset)?.bunsPlsBalance}
                                />
                            </div>
                            <div className="reward_pool_btn_group">
                                <WonderTreasuryTooltip plsValue={bunsTreasuryPlsBalance.toString()}>
                                    {chainId === pulsechain.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isTreasuryDistributeLoading)}
                                            onClick={treasutyDistributeClicked}
                                        >
                                            DISTRIBUTE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isTreasuryDistributeLoading)}
                                            onClick={() => switchToChain(pulsechain.id)}
                                        >
                                            SWITCH TO PULSE
                                        </button>
                                    )}
                                </WonderTreasuryTooltip>
                            </div>
                        </div>
                        <div className="cards_contents">
                            <h1>Janus Treasury </h1>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="PLS"
                                    name="Pulse"
                                    balance={parseFloat(etherToFixed(investmentPlsBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(investmentPlsBalance)) * pls_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={
                                        "This is the total amount of PLS earned from JNS taxes that goes to 50% JNS Buy & Burn / 50% Buy BUNS & add to Wonderland Treasury"
                                    }
                                />
                            </div>
                            <div className="reward_pool_btn_group">
                                <JNSInvestPoolTooltip plsValue={investmentPlsBalance}>
                                    {chainId === pulsechain.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isJnsTreasuryDistributeLoading)}
                                            onClick={jnsTreasutyDistributeClicked}
                                        >
                                            BUY & DISTRIBUTE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isJnsTreasuryDistributeLoading)}
                                            onClick={() => switchToChain(pulsechain.id)}
                                        >
                                            SWITCH TO PULSE
                                        </button>
                                    )}
                                </JNSInvestPoolTooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cols">
                    <div className="reward_pool_cards">
                        <div className="cards_contents">
                            <h1>Investment Pool </h1>

                            <div className="card_content_rewards">
                                <DashboardText
                                    token="ETH"
                                    name="Ethereum"
                                    balance={parseFloat(etherToFixed(investmentBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 4,
                                        maximumFractionDigits: 4,
                                    })}
                                    usd={(parseFloat(etherToFixed(investmentBalance)) * ether_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "balance" in asset)?.balance}
                                />
                            </div>

                            <div className="card_content_rewards">
                                <DashboardText
                                    token="ELMNT"
                                    name="Element 280"
                                    balance={parseFloat(etherToFixed(tokenData[E280_ADDRESS.toLowerCase()]?.tokenBalance || 0)).toLocaleString(
                                        "en-US",
                                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                                    )}
                                    usd={(tokenData[E280_ADDRESS.toLowerCase()]?.tokenUsdValue || 0).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("Element 280")}
                                />
                            </div>

                            {treasuryTokens.slice(0, -1).map((token) => {
                                const {
                                    tokenBalance = 0n,
                                    lpBalance = 0n,
                                    ownershipPercentage = 0,
                                    ownedValueInUSD = 0,
                                    tokenUsdValue = 0,
                                } = tokenData[token.address.toLowerCase()] || {};

                                return (
                                    <>
                                        {tokenBalance > 100000000000n && (
                                            <div className="card_content_rewards" key={token.address}>
                                                <DashboardText
                                                    token={token.ticker}
                                                    name={token.name}
                                                    balance={parseFloat(etherToFixed(tokenBalance)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                    usd={tokenUsdValue.toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                    tooltip={getTooltipText(token.name)}
                                                />
                                            </div>
                                        )}
                                        {lpBalance > 0n && (
                                            <div className="card_content_rewards" key={`${token.address}-lp`}>
                                                <DashboardText
                                                    token={`${token.ticker} LP`}
                                                    name={`${token.name} LP`}
                                                    balance={`${ownershipPercentage.toFixed(2)}%`}
                                                    usd={ownedValueInUSD.toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                    tooltip={`This value represents what percentage of the ELMNT/${token.ticker} Liquidy Pool is owned and the USD value it represents.`}
                                                />
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </TreasuryContentStyleWrapper>
    );
};

export default TreasuryContent;

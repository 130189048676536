export async function getCoinPrice(lpAddress, chain = "ethereum") {
    const options = { method: "GET", headers: { accept: "application/json" } };
    const result = await fetch(`https://api.dexscreener.com/latest/dex/pairs/${chain}/${lpAddress}`, options);
    if (result.status !== 200) throw Error("Failed to fetch price");
    const data = await result.json();
    if (!data.pair || !data.pair.priceUsd) {
        console.log("NO PAIR INFO FOR LP: ".lpAddress);
        return 0;
    }
    const nativePrice = data.pair.priceNative || undefined;
    return { usd: parseFloat(data.pair.priceUsd), native: nativePrice };
}

export function getTooltipText(tokenName) {
    return `This is the total amount of ${tokenName} available to be invested into new/existing Treasuries.  When and how is determined by DAO.  Head to our governance page to participate.`;
}

export function calculateTotalSum(value, price, decimals) {
    const divisor = 10 ** decimals;
    const tokenValue = Number(value) / divisor;
    const totalSum = tokenValue * price;

    return parseFloat(totalSum.toFixed(2));
}

export async function getCoinPricesGecko(tokensAddresses, chain = "eth") {
    try {
        const options = { method: "GET", headers: { accept: "application/json" } };
        const result = await fetch(`https://api.geckoterminal.com/api/v2/networks/${chain}/tokens/multi/${tokensAddresses.toString()}`, options);
        if (result.status !== 200) throw Error("Failed to fetch price");
        const data = await result.json();
        if (!data.data) throw Error(`Error fetching prices from Gecko`);
        const tokens = {};

        for (let i = 0; i < data.data.length; i++) {
            const tokenInfo = data.data[i].attributes;
            const tokenAddress = tokenInfo.address.toLowerCase();
            const price = Number(tokenInfo.price_usd) || 0;
            let marketCap;
            if (tokenInfo.market_cap_usd) {
                marketCap = Number(tokenInfo.market_cap_usd);
            } else {
                marketCap = calculateTotalSum(Number(tokenInfo.total_supply), price, tokenInfo.decimals);
            }
            tokens[tokenAddress] = {
                price,
                marketCap,
            };
        }
        return tokens;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getPoolInfosGecko(poolAddresses, chain = "eth") {
    try {
        const options = { method: "GET", headers: { accept: "application/json" } };
        const result = await fetch(`https://api.geckoterminal.com/api/v2/networks/${chain}/pools/multi/${poolAddresses.toString()}`, options);
        if (result.status !== 200) throw Error("Failed to fetch price");
        const data = await result.json();
        if (!data.data) throw Error(`Error fetching prices from Gecko`);
        const pools = [];

        for (let i = 0; i < poolAddresses.length; i++) {
            const poolData = data.data[i].attributes;
            const poolBalance = Number(poolData.reserve_in_usd) || 0;
            pools.push(poolBalance);
        }
        return pools;
    } catch (err) {
        console.log(err);
        return Array(poolAddresses.length).fill(0);
    }
}
